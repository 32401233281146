<template>
  <profiles-filter-list :link="true"></profiles-filter-list>
</template>
<script>

import ProfilesFilterList from "@/views/Profiles/Components/ProfilesFilterList";

export default {
  name: 'ProfilesPage',
  components: {
    ProfilesFilterList
  },
};
</script>
